import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useProfile } from '../../lib/auth';
import styles from './styles.module.scss';

const Banner: FC = () => {
    const profile = useProfile();

    return (
        <div
            className={styles.banner}
            style={{
                backgroundImage: 'url(/header-1.jpg)',
            }}
        >
            <Link to="/">
                <img src="/logo.png" />
            </Link>
            {profile && (
                <div className={`${styles.container} ${styles.copy}`}>
                    <h1>Welkom, {profile.fullName}</h1>
                    <p>Klik hieronder op een tegel om het beschikbare dashboard te bekijken.</p>
                </div>
            )}
            <div className={styles.hexacon}>
                <img src="/hexacon.png" />
            </div>
        </div>
    );
}

export default Banner;
