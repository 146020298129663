import { models } from 'powerbi-client';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PowerBIEmbed } from 'powerbi-client-react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { ApiDashboard, DashboardApi } from '../../models/Dashboard';
import styles from './styles.module.scss';
import { useProfile } from '../../lib/auth';

const DashboardEmbedView: FC = (): JSX.Element => {
    const [ notFound, setNotFound ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ dashboard, setDashboard ] = useState<ApiDashboard>();
    const { id } = useParams() as { id: string };
    const profile = useProfile();

    const fetch = useCallback(() => {
        setNotFound(false);
        setIsLoading(true);
        DashboardApi.getEmbed(id).then(({ data }) => {
            setDashboard(data);
            setNotFound(data.embedUrl === null);
        }).catch(() => {
            setNotFound(true);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [id]);
    useEffect(() => fetch(), [fetch]);

    if (!profile) {
        return <Loader />
    }

    return notFound || profile?.accessToken === '' ? (<>
        <h1>Dashboard bestaat niet</h1>
        <p>
            Het dashboard dat u zocht bestaat helaas niet.
        </p>
    </>) : (<>
        {/* <h1>{dashboard?.name}</h1> */}
        {isLoading && <Loader />}
        
        {dashboard?.embedUrl && profile && (
            <div className={styles.frame}>
                <PowerBIEmbed
                    embedConfig = {{
                        type: 'report',
                        id: dashboard.reportId,
                        embedUrl: dashboard.embedUrl,
                        accessToken: profile.accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                filters: {
                                    expanded: false,
                                    visible: false
                                },
                                pageNavigation: {
                                    visible: false,
                                }
                            },
                            background: models.BackgroundType.Transparent,
                        }
                    }}
                />
            </div>
        )}

        {dashboard?.description && (
            <div className={styles.description}>
                <FontAwesomeIcon icon={faCircleInfo} />
                <p>{dashboard?.description}</p>
            </div>
        )}
    </>);
}

export default DashboardEmbedView;
