export const c = (classes: any[]): string => {
    return classes.filter((o) => o !== '' && o !== false && o !== undefined).join(' ');
}

export const scrollTo = (selector: string): void => {
    const el = document.querySelector(`[data-name="${selector}"]`);
    const main = document.querySelector('main');
    if (!main || !el) return
    el.scrollIntoView({ behavior: 'auto' });
    setTimeout(() => main.scrollTo({ top: main.scrollTop - 32, behavior: 'auto' }), 10);
}
