import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import React, { ChangeEvent, FC, InputHTMLAttributes, isValidElement, useState } from 'react';
import styles from './styles.module.scss';
import { c } from '../../../lib/util';
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons';

export interface InputChangeProps {
    files?: FileList,
    name: string,
    value?: string | boolean,
}

export interface InputProps {
    error?: boolean | string,
    icon?: IconDefinition | JSX.Element,
    onChange?: (data: any, e: ChangeEvent<HTMLInputElement>) => void,
}

const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = ({
    className,
    error,
    icon,
    onChange,
    value,
    ...props
}): JSX.Element => {
    const [ focus, setFocus ] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange({
                files: e.currentTarget.files,
                name: e.currentTarget.name,
                value: /checkbox|radio/.test(props.type || '')
                    ? e.currentTarget.checked
                    :  e.currentTarget.value,
            }, e);
        }
    }

    return (
        <div
            className={c([
                styles.base,
                className,
                !error && focus && styles.focus,
                error && styles.hasError,
                (error || icon || props.type === 'date') && styles.hasIcon,
                props.type === 'date' ? styles.date : '',
            ])}
        >
            {(error || icon) && (
                <div className={styles.icon}>
                    {error ? (
                        <FontAwesomeIcon icon={faExclamationCircle} />
                    ) : (
                        isValidElement(icon)
                            ? icon
                            : <FontAwesomeIcon icon={icon as IconDefinition} />
                    )}
                </div>
            )}
            {!error && props.type === 'date' && (
                <div className={styles.icon}>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
            )}
            <input
                onBlur={(e) => {
                    setFocus(false);
                    if (props.onBlur) {
                        props.onBlur(e);
                    }
                }}
                onFocus={(e) => {
                    setFocus(true);
                    if (props.onFocus) {
                        props.onFocus(e);
                    }
                }}
                onChange={handleChange}
                value={value === undefined || value === null ? '' : value}
                {...props}
            />
        </div>
    )
}

export default Input;
