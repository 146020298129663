import { faPlus, faPencil, faTrashAlt, faEnvelope, faLockAlt, faCopy } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useEffect, useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Confirm, Segment, Menu, Table, toast, Button } from '../../kit';
import { ApiUser, UserApi } from '../../models/User';
import { roleOptions } from './Edit';
import { hasRole, useProfile } from '../../lib/auth';
import api, { ApiMeta } from '../../api';
import { useTableSort } from '../../kit/collections/Table';

interface UserListViewProps {
    customerId?: string,
    dashboardId?: string,
}

const UserListView: FC<UserListViewProps> = ({ customerId, dashboardId }): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ users, setUsers ] = useState<ApiUser[]>([]);
    const navigate = useNavigate();
    const profile = useProfile();
    const [ orderBy, order, setSorting ] = useTableSort('firstName');

    const fetch = useCallback((query?: string, page: number = 1): void => {
        setIsLoading(true);
        const limit = customerId || dashboardId ? 5 : 20;

        UserApi.list({ query, page, customerId, dashboardId, limit, orderBy, order }).then(({ data }) => {
            setMeta(data.meta);
            setUsers(data.data);
            setIsLoading(false);
        });
    }, [orderBy, order]);
    useEffect(() => fetch(), [orderBy, order, fetch]);

    const deleteUser = (userId: string): void => {
        if (window.confirm('Weet je zeker dat je deze gebruiker wilt verwijderen?')) {
            UserApi.delete(userId).then(() => {
                fetch();
                toast('Gebruiker succesvol verwijderd');
            });
        }
    }

    const resetPassword = (userId: string): void => {
        UserApi.resetPassword(userId).then(() => {
            toast('Uitnodiging verstuurd');
        });
    }

    const superLogin = (userId: string): void => {
        api.superLogin(userId).then(() => {
            navigate('/');
        });
    }

    return (<>
        {!customerId && !dashboardId && (
            <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Gebruikers
                <Button
                    color="blue"
                    href="create"
                    icon={faPlus}
                    label="Nieuwe gebruiker"
                />
            </h1>
        )}
        <Segment isLoading={isLoading}>
            <Table.Actions
                onSearch={fetch}
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell
                            sorted={[orderBy, 'firstName']}
                            order={order}
                            onSort={setSorting}
                        >
                            Naam
                        </Table.HeaderCell>
                        {!customerId && !dashboardId && hasRole(profile, [1]) && (
                            <Table.HeaderCell>
                                Klant(en)
                            </Table.HeaderCell>
                        )}
                        {!customerId && !dashboardId && hasRole(profile, [1]) && (
                            <Table.HeaderCell collapsing>
                                Rollen
                            </Table.HeaderCell>
                        )}
                        <Table.HeaderCell collapsing>
                            E-mailadres
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {users.length > 0 ? users.map((user) => (
                        <Table.Row key={`row-${user.id}`}>
                            <Table.Cell>
                                <Link to={`/admin/users/${user.id}/edit`}>
                                    {user.fullName}
                                </Link>
                            </Table.Cell>
                            {!customerId && !dashboardId && hasRole(profile, [1]) && (
                                <Table.Cell>
                                    {user.customers.length > 0 ? user.customers.map((o) => o.name).join(', ') : '-'}
                                </Table.Cell>
                            )}
                            {!customerId && !dashboardId && hasRole(profile, [1]) && (
                                <Table.Cell collapsing>
                                    {roleOptions.filter(o => user.roles.includes(o.value)).map(o => o.text).join(', ') || '-'}
                                </Table.Cell>
                            )}
                            <Table.Cell collapsing>
                                {user.emailAddress}
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/admin/users/${user.id}/edit`}
                                    />
                                    <Menu.Item
                                        icon={faEnvelope}
                                        content="Nieuw wachtwoord verzenden"
                                        onClick={() => resetPassword(user.id)}
                                    />
                                    {hasRole(profile, [1]) && profile?.id !== user.id && (<>
                                        <Menu.Item
                                            icon={faCopy}
                                            content="Kopieer wachtwoord URL"
                                            onClick={() => {
                                                navigator.clipboard.writeText(user.resetLink || '');
                                                toast('Link gekopieerd naar klembord.');
                                            }}
                                        />
                                        <Menu.Divider />
                                        <Menu.Item
                                            icon={faLockAlt}
                                            content="Inloggen"
                                            onClick={() => superLogin(user.id)}
                                        />
                                    </>)}
                                    {profile?.id !== user.id && (<>
                                        <Menu.Divider />
                                        <Menu.Item
                                            icon={faTrashAlt}
                                            content="Verwijderen"
                                            onClick={() => deleteUser(user.id)}
                                        />
                                    </>)}
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default UserListView;
