import React, { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import styles from './styles.module.scss';
import { c } from '../../../lib/util';

type ColorType = 'border' | 'blue' | 'orange' | 'transparent';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    block?: boolean,
    color: ColorType,
    disabled?: boolean,
    href?: string,
    icon?: IconDefinition,
    iconPosition?: 'right',
    label?: string,
    target?: string,
    tooltip?: string,
    type?: string,
}

const Button: FC<ButtonProps> = ({
    block,
    color,
    disabled,
    children,
    className,
    href,
    icon,
    iconPosition,
    label,
    onClick,
    target,
    tooltip,
    type,
    ...props
}) => {
    const elemProps: any = {
        className: c([
            styles.base,
            block && styles.block,
            disabled && styles.disabled,
            color && styles[`${color}`],
            icon && !children && !label ? styles.iconOnly : '',
            icon && iconPosition ? styles.iconRight : '',
            className,
        ]),
        onClick: disabled ? undefined : onClick,
        ...props
    }

    const tt = tooltip && (
        <div className={styles.tooltip}>
            {tooltip}
        </div>
    );

    const innerContent = icon ? (<>
        <FontAwesomeIcon icon={icon} />
        {(children || label) && (<span>{children || label}</span>)}
    </>) : (children || label);

    if (href && !disabled) {
        if (target) {
            elemProps.href = href;
            elemProps.target = target;
            return <a {...elemProps}>{innerContent}{tt}</a>;
        } else {
            elemProps.to = href;
            return <Link {...elemProps}>{innerContent}{tt}</Link>;
        }
    }

    return <button {...elemProps} type={type || 'button'}>{innerContent}{tt}</button>
}

export default Button;
