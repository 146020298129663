import React, { FC, HTMLAttributes } from 'react';
import { c } from '../../lib/util';
import styles from './styles.module.scss';

const Loader: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }): JSX.Element => {
    return (
        <div
            className={c([styles.circle, className])}
            {...props}
        >
            <span />
        </div>
    );
}

export default Loader;
