import React, { FC, HTMLAttributes, CSSProperties, isValidElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { OrderType } from './';
import styles from './styles.module.scss';

export interface TableCellProps extends HTMLAttributes<HTMLTableDataCellElement & HTMLTableHeaderCellElement> {
    actions?: boolean,
    align?: 'center' | 'left' | 'right',
    as?: 'td' | 'th',
    collapsing?: boolean,
    icon?: IconDefinition | JSX.Element,
    name?: string,
    onExpand?: () => void,
    onSort?: (orderBy: string, order: OrderType) => void
    order?: OrderType,
    primary?: boolean,
    sorted?: [string, string],
    responsive?: boolean,
}

const TableCell: FC<TableCellProps> = ({
    actions,
    align,
    as,
    children,
    className,
    collapsing,
    icon,
    name,
    onExpand,
    onSort,
    order,
    primary,
    sorted,
    responsive,
    ...props
}): JSX.Element => {
    const Elem = as ? as : 'td';

    const style: CSSProperties = {};
    if (props.onClick) {
        style.cursor = 'pointer';
    }

    const handleSort = (e: any): void => {
        if (onSort && sorted) {
            onSort(sorted[1], order === 'ASC' ? 'DESC' : 'ASC');
        }

        if (props.onClick) {
            props.onClick(e);
        }
    }
    
    return (
        <Elem
            className={[
                collapsing && styles.collapsing,
                primary && styles.primary,
                actions && styles.actions,
                responsive === false && styles.notResponsive,
                align && styles[`${align}Align`],
                className,
            ].filter(o => o).join(' ')}
            style={style}
            onClick={(e) => handleSort(e)}
            {...props}
        >
            <div data-name={name} style={{ width: '100%' }}>
                {icon && (isValidElement(icon) ? icon : <FontAwesomeIcon icon={icon as IconDefinition} style={{ marginRight: children ? 5 : 0 }} />)}
                {children}
                {sorted && sorted[0] === sorted[1] && <FontAwesomeIcon
                    className={styles.sortIcon}
                    icon={order === 'ASC' ? faArrowUp : faArrowDown}
                />}
            </div>
            {primary && (
                <div className={styles.collapseIcon} onClick={onExpand}>
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            )}
        </Elem>
    );
}

export default TableCell;
