import { uniq } from 'lodash';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api';
import { ApiProfile } from '../../api/auth';
import { Button, Checkbox, Form, Segment, toast, Grid, DropdownOption } from '../../kit';
import { hasRole, useProfile } from '../../lib/auth';
import { CustomerApi } from '../../models/Customer';
import { DashboardApi } from '../../models/Dashboard';
import { ApiUser, UserApi } from '../../models/User';
import TwoStep from './TwoStep';

export const roleOptions: DropdownOption[] = [{
    text: 'Administrator',
    value: 1,
}, {
    text: 'Klantbeheerder',
    value: 2,
}, {
    text: 'Gebruiker',
    value: 3,
}];

const UserEditView: FC = () => {
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean|string>(true);
    const [ user, setUser ] = useState<Partial<ApiUser>>({});
    const [ invite, setInvite ] = useState<boolean>(false);
    const [ customerOptions, setCustomerOptions ] = useState<DropdownOption[]>([]);
    const [ dashboardOptions, setDashboardOptions ] = useState<DropdownOption[]>([]);
    const [ scopeOptions, setScopeOptions ] = useState<string[]>([]);
    const [ password, setPassword ] = useState<string>('');
    const [ newPassword, setNewPassword ] = useState<string>('');
    const [ confirmPassword, setConfirmPassword ] = useState<string>('');
    const navigate = useNavigate();
    const { id } = useParams();
    const profile = useProfile();
    const isAccount = window.location.pathname === '/account';

    const fetch = useCallback(() => {
        if (id || isAccount) {
            setIsLoading(true);
            UserApi.get(isAccount ? (profile?.id || '') : (id || ''), isAccount ? '' : 'customers,dashboards').then(({ data }) => {
                setUser({
                    ...data,
                    customers: data.customers ? data.customers.map(o => o.id) : [],
                    dashboards: data.dashboards ? data.dashboards.map(o => o.id) : [],
                });
                setIsLoading(false);

                if (data.customers) {
                    const newScopes: string[] = [];
                    data.customers.map((o: any) => {
                        (o.scopes ? o.scopes.replace(/, /g, ',').split(',') : [])
                        .forEach((s: string) => newScopes.push(s));
                    });
                    setScopeOptions(uniq(newScopes));
                }
            });
        }
    }, [id, isAccount, profile])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch, isAccount]);

    useEffect(() => {
        if (hasRole(profile, [1, 2]) && !isAccount && hasRole(user as ApiProfile, [2, 3])) {
            if (hasRole(profile, [1]) && customerOptions.length <= 0) {
                CustomerApi.list({ limit: 999 }).then(({ data }) => {
                    setCustomerOptions(data.data.map((o) => ({
                        text: o.name,
                        value: o.id,
                        extra: {
                            scopes: o.scopes ? o.scopes.replace(/, /g, ',').split(',') : [],
                        }
                    })));
                });
            }
            if (dashboardOptions.length <= 0) {
                DashboardApi.list({ limit: 999, skipp: hasRole(profile, [2]) ? 1 : 0 }).then(({ data }) => {
                    setDashboardOptions(data.data.map((o) => ({
                        text: o.name,
                        value: o.id,
                    })));
                });
            }
        }
    }, [user, isAccount, customerOptions, dashboardOptions]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setUser({
            ...user,
            [name]: value,
        });
    }

    const handleCustomerInput = ({ name, value, options }: { [key: string]: any }): void => {
        const newScopes = [ ...scopeOptions ];
        options.map((o: any) => {
            o.extra.scopes.forEach((s: string) => newScopes.push(s));
        });
        setScopeOptions(uniq(newScopes));
        handleInput({ name, value });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        UserApi.put({
            ...user,
            isActive: user.isActive === undefined ? true : user.isActive,
        }).then(({ data }) => {
            setIsLoading(false);
            
            if (isAccount) {
                toast('Account succesvol bijgewerkt');
                api.getProfile();
            } else {
                if (invite) {
                    UserApi.resetPassword(data.id);
                    setInvite(false);
                }
                toast('Gebruiker succesvol opgeslagen');
                navigate('/admin/users');
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    const savePassword = (): void => {
        if (password === '' || newPassword === '') return;
        setErrors({});
        setIsLoading('password');

        if (newPassword !== confirmPassword) {
            setErrors({ confirmPassword: 'Wachtwoorden komen niet overeen' });
            toast('Wachtwoorden komen niet overeen', 'error');
            setIsLoading(false);
        } else {
            api.savePassword(password, newPassword).then(() => {
                setPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setIsLoading(false);
                toast('Wachtwoord gewijzigd', 'success');
            }).catch((err) => {
                setErrors(err.response.data.errors);
                setIsLoading(false);
                toast('Er ging iets mis', 'error');
            });
        }
    }

    return (<>
        {isAccount ? (
            <h1>Mijn account</h1>
        ) : (
            <h1>{id ? 'Wijzig' : 'Nieuwe'} gebruiker</h1>
        )}
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column lg={8}>
                    <Segment isLoading={isLoading === true} header="Gegevens">
                        {!isAccount && (<>
                            <Form.Dropdown
                                label="Status"
                                name="isActive"
                                onChange={handleInput}
                                options={[{
                                    text: 'Actief',
                                    value: true,
                                }, {
                                    text: 'Inactief',
                                    value: false,
                                }]}
                                value={user.isActive === undefined ? true : user.isActive}
                            />
                            {hasRole(profile, [1]) && (
                                <Form.Dropdown
                                    label="Rol"
                                    name="roles"
                                    onChange={handleInput}
                                    options={roleOptions}
                                    placeholder="Selecteer rol(len)"
                                    required
                                    multiple
                                    value={user.roles || []}
                                />
                            )}
                        </>)}
                        <Grid.Row>
                            <Grid.Column md={4}>
                                <Form.Input
                                    error={errors.firstName}
                                    label="Voornaam"
                                    name="firstName"
                                    onChange={handleInput}
                                    required
                                    value={user.firstName || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={3}>
                                <Form.Input
                                    label="Tussnv."
                                    name="affix"
                                    onChange={handleInput}
                                    value={user.affix || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={5}>
                                <Form.Input
                                    error={errors.lastName}
                                    label="Achternaam"
                                    name="lastName"
                                    onChange={handleInput}
                                    required
                                    value={user.lastName || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ marginBottom: 16 }}>
                            <Grid.Column md={6}>
                                <Form.Input
                                    error={errors.emailAddress}
                                    label="E-mailadres"
                                    name="emailAddress"
                                    onChange={handleInput}
                                    required
                                    value={user.emailAddress || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={6}>
                                <Form.Input
                                    error={errors.telephoneNumber}
                                    label="Telefoonnummer"
                                    name="telephoneNumber"
                                    onChange={handleInput}
                                    value={user.telephoneNumber || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {!isAccount && (
                            <Form.Group>
                                <Checkbox
                                    checked={invite}
                                    label="Verstuur direct een uitnodiging"
                                    onChange={({ value }: any) => setInvite(value)}
                                />
                            </Form.Group>
                        )}
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                color="blue"
                                type="submit"
                            />
                            {!isAccount && (
                                <Button
                                    color="transparent"
                                    href="/admin/users"
                                    label="Annuleren"
                                />
                            )}
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column lg={4}>
                    {!isAccount && (hasRole(profile, [2]) || hasRole(user as ApiProfile, [2, 3])) && (<>
                        <Segment header="Rechten">
                            {hasRole(profile, [1]) && (
                                <Form.Dropdown
                                    name="customers"
                                    onChange={(data) => handleCustomerInput(data)}
                                    options={customerOptions}
                                    placeholder="Selecteer klant(en)"
                                    multiple
                                    value={user.customers || []}
                                />
                            )}
                            {((profile?.scopes || []).length > 0 || (user.customers || []).length > 0) && (
                                <Form.Dropdown
                                    name="scopes"
                                    onChange={handleInput}
                                    options={(hasRole(profile, [1]) ? scopeOptions : (profile?.scopes || scopeOptions)).map((o) => ({
                                        text: o,
                                        value: o,
                                    }))}
                                    placeholder="Selecteer scope(s)"
                                    multiple
                                    value={user.scopes || []}
                                />
                            )}
                            <Form.Input
                                label="RLS-key"
                                name="rlsKey"
                                onChange={handleInput}
                                value={user.rlsKey || ''}
                            />
                        </Segment>
                        {hasRole(profile, [1, 2]) && (
                            <Segment header="Dashboards">
                                {hasRole(profile, [1]) && (
                                    <p style={{ fontSize: 14, marginBottom: 16 }}>
                                        Let op: dit zijn 1-op-1 gekoppelde dashboards. Dashboards gekoppeld op klant worden hier niet getoond.
                                    </p>
                                )}
                                <Form.Dropdown
                                    name="dashboards"
                                    onChange={handleInput}
                                    options={dashboardOptions}
                                    placeholder="Selecteer dashboard(s)"
                                    multiple
                                    value={user.dashboards || []}
                                />
                            </Segment>
                        )}
                    </>)}
                    {isAccount && (<>
                        <Segment header="Beveiliging" isLoading={isLoading === 'password'}>
                            <div style={{ marginBottom: '.5rem' }}>
                                <Form.Input
                                    error={errors.password}
                                    placeholder="Huidig wachtwoord"
                                    onChange={({ value }) => setPassword(value)}
                                    value={password}
                                    type="password"
                                />
                            </div>
                            <div style={{ marginBottom: '.5rem' }}>
                                <Form.Input
                                    error={errors.newPassword}
                                    placeholder="Nieuw wachtwoord"
                                    onChange={({ value }) => setNewPassword(value)}
                                    value={newPassword}
                                    type="password"
                                />
                            </div>
                            <Form.Input
                                error={errors.confirmPassword}
                                placeholder="Bevestig nieuw wachtwoord"
                                onChange={({ value }) => setConfirmPassword(value)}
                                value={confirmPassword}
                                type="password"
                            />
                            <Button
                                color="blue"
                                label="Stel wachtwoord in"
                                onClick={() => savePassword()}
                                type="button"
                            />
                        </Segment>
                        <TwoStep
                            onUpdate={(value) => handleInput({ name: 'has2fa', value })}
                            user={user}
                        />
                    </>)}
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default UserEditView;
