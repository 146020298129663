import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from '../../api';

export interface ApiCustomer {
    id: string,
    name: string,
    role: string,
    scopes?: string,
    dashboards?: any[],
}

const rest = {
    delete: (customerId: string): AxiosPromise => {
        return request.delete(`customers/${customerId}`);
    },
    get: (customerId: string, qWith?: string): AxiosPromise<ApiCustomer> => {
        return request.get(`customers/${customerId}${qWith ? `?with=${qWith}` : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiCustomer[]>> => {
        return request.get(`customers?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (customer: Partial<ApiCustomer>): AxiosPromise<ApiCustomer> => {
        return request.post(`customers${customer.id ? `/${customer.id}` : ''}`, customer);
    },
}

export default rest;
