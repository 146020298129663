import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Segment, toast, Grid, DropdownOption } from '../../kit';
import { ApiCustomer, CustomerApi } from '../../models/Customer';
import { DashboardApi } from '../../models/Dashboard';
import UserListView from '../UserView';

const CustomerEditView: FC = () => {
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ customer, setCustomer ] = useState<Partial<ApiCustomer>>({});
    const [ dashboardOptions, setDashboardOptions ] = useState<DropdownOption[]>([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        CustomerApi.get(id, 'dashboards').then(({ data }) => {
            setCustomer({
                ...data,
                dashboards: data.dashboards ? data.dashboards.map((o) => o.id) : [],
            });
            setIsLoading(false);
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        fetch();
    }, [id, fetch]);

    useEffect(() => {
        if (dashboardOptions.length <= 0) {
            DashboardApi.list({ limit: 999 }).then(({ data }) => {
                setDashboardOptions(data.data.map((o) => ({
                    text: o.name,
                    value: o.id,
                })));
            });
        }
    }, [dashboardOptions]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setCustomer({
            ...customer,
            [name]: value,
        });
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        CustomerApi.put(customer).then(() => {
            setIsLoading(false);
            toast('Klant succesvol opgeslagen');
            navigate('/admin/customers');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <h1>{id ? 'Wijzig' : 'Nieuwe'} klant</h1>
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column lg={6}>
                    <Segment isLoading={isLoading} header="Gegevens">
                        <Form.Input
                            error={errors.name}
                            label="Naam"
                            name="name"
                            onChange={handleInput}
                            required
                            value={customer.name || ''}
                        />
                        <Form.Input
                            error={errors.role}
                            label="Power BI rol"
                            name="role"
                            onChange={handleInput}
                            required
                            value={customer.role || ''}
                        />
                        <Form.Input
                            info="Komma gescheiden (,). Wanneer je een scope verwijdert/aanpast, zal deze bij gekoppelde gebruikers ook verwijderd worden."
                            error={errors.scopes}
                            label="Power BI scopes"
                            name="scopes"
                            onChange={handleInput}
                            value={customer.scopes || ''}
                        />
                        <Form.Group>
                            <Button
                                label="Opslaan"
                                color="blue"
                                type="submit"
                            />
                            <Button
                                color="transparent"
                                href="/admin/customers"
                                label="Annuleren"
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column lg={6}>
                    <Segment header="Dashboards">
                        <p style={{ fontSize: 14, marginBottom: 16 }}>
                            Let op: alle gebruikers van deze klant zullen toegang hebben tot deze dashboards.
                        </p>
                        <Form.Dropdown
                            name="dashboards"
                            onChange={handleInput}
                            options={dashboardOptions}
                            placeholder="Selecteer dashboard(s)"
                            multiple
                            value={customer.dashboards || []}
                        />
                    </Segment>
                    {customer.id && <UserListView customerId={customer.id} />}
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default CustomerEditView;
