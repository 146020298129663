import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '../../kit';
import logo from './logo.png';
import styles from './styles.module.scss';
import { useProfile } from '../../lib/auth';

const Footer: FC = (): JSX.Element => {
    const profile = useProfile();

    return (
        <div className={styles.base}>
            <div className="container">
                <Grid.Row>
                    <Grid.Column md={8}>
                        <Link
                            className={styles.logo}
                            to="/"
                        >
                            <img src={logo} alt="Eurofins Clinical Diagnostics" />
                        </Link>
                        <p className={styles.address}>
                        Visseringlaan 25<br />
                        2288 ER Rijswijk
                        </p>
                    </Grid.Column>
                    <Grid.Column md={4}>
                        <div className={styles.navigation}>
                            <Link to="/">Informatie</Link>
                            <span>|</span>
                            <a href={`mailto:relatiebeheer@nmdl-lcpl.nl?subject=Betreft+Eurofins+Data4Life&body=%0d%0a%0d%0aWebsite:${encodeURIComponent('https://eurofins-data4life.nl/')}%0d%0aGebruiker:+${encodeURIComponent(profile?.fullName || '')}`}>Contact</a>
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </div>
        </div>
    );
}

export default Footer;
