import debounce from 'lodash/debounce';
import { useEffect, useRef, useState } from 'react';

export const useDebounceSearch = (callback?: (query: string) => void, delay: number = 300) => {
    const [ debounceSearch ] = useState(() => debounce(callback ? callback : () => {}, delay));
    return debounceSearch;
};

export const useHandleKeyUp = (callback: (key: string, e: Event) => void, keys: string[]) => {
    useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            if (keys.includes(e.key)) {
                callback(e.key, e);
            }
        }

        window.addEventListener('keyup', handleKeyUp);
        return () => window.removeEventListener('keyup', handleKeyUp);
    });
}

export const useInterval = (callback: any, delay: number | null): void => {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const tick = (): void => {
            const cb: any = callbackRef.current;
            cb();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const useKeyUp = (
    callback: (keyCode: string, e: KeyboardEvent) => void,
    keys: string[],
    force: boolean = false
) => {
    const prevent = false;//useSelector((state: AppState) => state.control.preventKeyup);
    
    useEffect(() => {
        if (prevent && !force) return;

        const handleKeyUp = (e: KeyboardEvent) => {
            if (keys.includes(e.key)) {
                callback(e.key, e);
            }
        }

        window.addEventListener('keyup', handleKeyUp);
        return () => window.removeEventListener('keyup', handleKeyUp);
    }, [callback, force, keys, prevent]);
}

export const useTimeout = (callback: any, deps: any[] = [], delay: number = 300) => {
    const callbackRef = useRef();

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const id = setTimeout((): void => {
            const cb: any = callbackRef.current;
            cb();
        }, delay);
        return () => clearInterval(id);
    }, [ callback, ...deps] ); // eslint-disable-line
}

export const useWindowResize = (callback: (e: Event) => void, delay: number = 300) => {
    useEffect(() => {
        let resizeTimer: NodeJS.Timeout; 

        const handleResize = (e: Event) => {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [callback, delay]);
}

export const useWindowScroll = (callback: (e: Event) => void, delay: number = 300, deps: any[] = []) => {
    useEffect(() => {
        let scrollTimer: NodeJS.Timeout; 

        const handleScroll = (e: Event) => {
            clearTimeout(scrollTimer);
            scrollTimer = setTimeout(() => callback(e), delay);
        }

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
