import React, { FC, useEffect, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import api from './api';
import NotificationCenter from './kit/modules/NotificationCenter';
import Footer from './modules/Footer';
import { hasRole, useProfile } from './lib/auth';
import TopBar from './modules/TopBar';
import styles from './styles.module.scss';
import LoginView from './views/auth/LoginView';
import ResetPasswordView from './views/auth/ResetPasswordView';
import CustomerListView from './views/CustomerView';
import CustomerEditView from './views/CustomerView/Edit';
import DashboardListView from './views/DashboardView';
import DashboardEditView from './views/DashboardView/Edit';
import DashboardEmbedView from './views/DashboardView/Embed';
import UserListView from './views/UserView';
import UserEditView from './views/UserView/Edit';
import HomepageView from './views/HomepageView';
import { useInterval } from './lib/hooks';

const App: FC = (): JSX.Element => {
    const [ sec, setSec ] = useState<number>(0);
    const profile = useProfile();
    const isFullView = profile && !/^\/admin|account/.test(window.location.pathname);// && window.location.pathname !== '/';

    useInterval(() => {
        setSec((s) => s + 1);
    }, 1000);

    useEffect(() => {
        if (profile && sec >= 900) {
            setSec(0);
            api.getProfile();
        }
    }, [profile, sec]);
    
    useEffect(() => {
        api.getProfile();
    }, []);

    return (
        <BrowserRouter>
            <TopBar />
            <main className={isFullView ? styles.fullView : ''}>
                <div className={styles.container}>
                    <Routes>
                        {profile ? (<>
                            <Route
                                element={<HomepageView />}
                                index
                            />
                            <Route
                                element={<UserEditView />}
                                path="/account"
                            />
                            <Route
                                element={<DashboardEmbedView />}
                                path="/dashboards/:id"
                            />
                            {profile.dashboards.length > 0 && (
                                <Route
                                    path="*"
                                    element={<Navigate to="/" />}
                                />
                            )}
                        </>) : (<>
                            <Route path="/auth/reset-password" element={<ResetPasswordView />} />
                            <Route path="*" element={<LoginView />} />
                        </>)}
                        {hasRole(profile, [1, 2]) && (<>
                            <Route
                                element={<Outlet />}
                                path="/admin"
                            >
                                {hasRole(profile, [1]) && (<>
                                    <Route
                                        element={<Outlet />}
                                        path="/admin/customers"
                                    >
                                        <Route
                                            element={<CustomerListView />}
                                            index
                                        />
                                        <Route
                                            element={<CustomerEditView />}
                                            path="create"
                                        />
                                        <Route
                                            element={<CustomerEditView />}
                                            path=":id/edit"
                                        />
                                    </Route>
                                    <Route
                                        element={<Outlet />}
                                        path="/admin/dashboards"
                                    >
                                        <Route
                                            element={<DashboardListView />}
                                            index
                                        />
                                        <Route
                                            element={<DashboardEditView />}
                                            path="create"
                                        />
                                        <Route
                                            element={<DashboardEditView />}
                                            path=":id/edit"
                                        />
                                    </Route>
                                </>)}
                                <Route
                                    element={<Outlet />}
                                    path="/admin/users"
                                >
                                    <Route
                                        element={<UserListView />}
                                        index
                                    />
                                    <Route
                                        element={<UserEditView />}
                                        path="create"
                                    />
                                    <Route
                                        element={<UserEditView />}
                                        path=":id/edit"
                                    />
                                </Route>
                            </Route>
                        </>)}
                    </Routes>
                </div>
            </main>
            <Footer />
            <NotificationCenter />
        </BrowserRouter>
    );
}

export default App;
