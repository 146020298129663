import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from '../../api';

export interface ApiDashboard {
    id: string,
    reportId?: string,
    name: string,
    displayName: string,
    description?: string,
    imagePath?: string,
    embedUrl?: string,
    embedToken?: string,
}

const rest = {
    delete: (dashboardId: string): AxiosPromise => {
        return request.delete(`dashboards/${dashboardId}`);
    },
    get: (dashboardId: string, qWith?: string): AxiosPromise<ApiDashboard> => {
        return request.get(`dashboards/${dashboardId}${qWith ? `?with=${qWith}` : ''}`);
    },
    getEmbed: (dashboardId: string): AxiosPromise<ApiDashboard> => {
        return request.get(`dashboards/${dashboardId}/embed`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiDashboard[]>> => {
        return request.get(`dashboards?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (dashboard: Partial<ApiDashboard>): AxiosPromise<ApiDashboard> => {
        return request.post(`dashboards${dashboard.id ? `/${dashboard.id}` : ''}`, dashboard);
    },
    uploadImage: (dashboardId: string, file: File): AxiosPromise => {
        const data = new FormData();
        data.append('file', file);
        return request.post(`dashboards/${dashboardId}/image`, data);
    },
    deleteImage: (dashboardId: string): AxiosPromise => {
        return request.post(`dashboards/${dashboardId}/image/delete`);
    },
}

export default rest;
