import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { request } from '../../api';
import { Button, Form, Segment, toast, Grid } from '../../kit';
import { ApiDashboard, DashboardApi } from '../../models/Dashboard';
import CustomerListView from '../CustomerView';
import UserListView from '../UserView';
import styles from './styles.module.scss';

const DashboardEditView: FC = () => {
    const [ errors, setErrors ] = useState<any>({});
    const [ file, setFile ] = useState<File>();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ dashboard, setDashboard ] = useState<Partial<ApiDashboard>>({});
    const [ reports, setReports ] = useState<any[]>([]);
    const navigate = useNavigate();
    const { id } = useParams();

    const fetch = useCallback(() => {
        if (!id) return;
        setIsLoading(true);
        DashboardApi.get(id).then(({ data }) => {
            setDashboard(data);
            setIsLoading(false);
        });
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
        }

        request.get('azure/reports').then(({ data }) => {
            setReports(data.reports);
        });

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setDashboard({
            ...dashboard,
            [name]: value,
        });
    }

    const deleteImage = (dashboardId: string) => {
        if (window.confirm('Weet je het zeker?')) {
            DashboardApi.deleteImage(dashboardId).then(() => {
                toast('Afbeelding verwijderd');
                fetch();
            })
        }
    }

    const save = (e: FormEvent): void => {
        e.preventDefault();
        setIsLoading(true);

        DashboardApi.put(dashboard).then(({ data }) => {
            if (file) {
                DashboardApi.uploadImage(data.id, file);
                setFile(undefined);
            }
            setIsLoading(false);
            toast('Dashboard succesvol opgeslagen');
            navigate('/admin/dashboards');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast('Er ging iets mis', 'error')
        });
    }

    return (<>
        <h1>{id ? 'Wijzig' : 'Nieuw'} dashboard</h1>
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column lg={6}>
                    <Segment isLoading={isLoading} header="Gegevens">
                        <Form.Input
                            error={errors.name}
                            label="Naam"
                            name="name"
                            onChange={handleInput}
                            required
                            value={dashboard.name || ''}
                        />
                        <Form.Input
                            error={errors.name}
                            label="Weergave naam"
                            name="displayName"
                            onChange={handleInput}
                            value={dashboard.displayName || ''}
                        />
                        <Form.Dropdown
                            error={errors.reportId}
                            label="Power BI report"
                            name="reportId"
                            onChange={handleInput}
                            options={reports.map((o) => ({
                                text: o.name,
                                value: o.id,
                            }))}
                            required
                            value={dashboard.reportId || ''}
                        />
                        <Form.Textarea
                            autoGrow
                            label="Extra informatie"
                            name="description"
                            onChange={handleInput}
                            value={dashboard.description || ''}
                        />
                        <Form.Input
                            error={errors.file}
                            label="Afbeelding"
                            name="file"
                            onChange={({ files }) => setFile(files[0])}
                            type="file"
                            accept="image/*"
                        />
                        {dashboard.imagePath && (
                            <div className={styles.preview}>
                                <img src={dashboard.imagePath} alt="" />
                                <Button
                                    color="transparent"
                                    icon={faTrashAlt}
                                    onClick={() => deleteImage(dashboard.id || '')}
                                />
                            </div>
                        )}

                        <Form.Group>
                            <Button
                                label="Opslaan"
                                color="blue"
                                type="submit"
                            />
                            <Button
                                color="transparent"
                                href="/admin/dashboards"
                                label="Annuleren"
                            />
                        </Form.Group>
                    </Segment>
                </Grid.Column>
                <Grid.Column lg={6}>
                    {dashboard.id && (<>
                        <div style={{ marginBottom: 32 }}>
                            <CustomerListView dashboardId={dashboard.id} />
                        </div>
                        <UserListView dashboardId={dashboard.id} />
                    </>)}
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default DashboardEditView;
