import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import './kit/index.scss';

const root = createRoot(document.getElementById('root') as Element);
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
