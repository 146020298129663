import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faCaretUp } from '@fortawesome/pro-solid-svg-icons';
import find from 'lodash/find';
import React, { FC, HTMLAttributes, useEffect, useState, useRef } from 'react';
import Button from '../Button';
import { useDebounceSearch } from '../../../lib/hooks';
import styles from './styles.module.scss';
import { c } from '../../../lib/util';

type DropdownOptionType = 'group';

export interface DropdownOption {
    disabled?: boolean,
    text: string,
    type?: DropdownOptionType,
    value: any,
}

export interface DropdownProps extends HTMLAttributes<HTMLSelectElement> {
    autoComplete?: (query: string, callback: (options: DropdownOption[]) => void) => void,
    clearable?: boolean,
    defaultOption?: string,
    error?: boolean | string,
    extendSelected?: (value: DropdownOption) => JSX.Element | undefined,
    multiple?: boolean,
    name?: string,
    onChange?: (value: any) => void,
    options?: DropdownOption[],
    value?: any,
}

const Dropdown: FC<DropdownProps> = ({
    autoComplete,
    clearable,
    className,
    defaultOption,
    error,
    extendSelected,
    onChange,
    options,
    multiple,
    name,
    placeholder,
    value,
    ...props
}): JSX.Element => {
    const [ defOpt, setDefOpt ] = useState<DropdownOption | undefined>((defaultOption
        ? { text: defaultOption, value: '' }
        : undefined));
    const [ focus, setFocus ] = useState<boolean>(false);
    const [ query, setQuery ] = useState<string>('');
    const [ actualOptions, setActualOptions ] = useState<DropdownOption[]>([]);
    const [ selectedOptions, setSelectedOptions ] = useState<DropdownOption[]>([]);
    const [ selectedOption, setSelectedOption ] = useState<DropdownOption>();
    const [ up, setUp ] = useState<boolean>(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    
    const debounceAutoComplete = useDebounceSearch((q) => {
        if (autoComplete) {
            autoComplete(q, (opts) => {
                setActualOptions(opts)
            });
        }
    });

    useEffect(() => {
        setActualOptions(autoComplete ? [] : (options || []));
    }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (query !== '') {
            debounceAutoComplete(query);
        }
    }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

    const filter = (option: DropdownOption): boolean => {
        if (autoComplete) {
            return true;
        }
        return option.text.toLowerCase().indexOf(query.toLocaleLowerCase()) !== -1;
    }

    const handleClick = (option?: DropdownOption): void => {
        handleFocus(false);
        let newOptions: DropdownOption[] = [];

        if (multiple && option) {
            newOptions = [
                ...selectedOptions,
                option,
            ];

            setSelectedOptions(newOptions);
        }
        
        if (onChange) {
            onChange({
                name,
                value: multiple ? newOptions.map(o => o.value) : option?.value,
                [multiple ? 'options' : 'option']: multiple ? newOptions : option,
            });
        }
    }

    const handleFocus = (state: boolean, e:any = undefined): void => {
        if (state === true) {
            dispatchEvent(new CustomEvent('blur-dropdown'));
        }

        setFocus(state);
        
        if (state === false) {
            setQuery('');
        }

        if (state && props.onFocus) {
            props.onFocus(e);
        } else if (!state && props.onBlur) {
            props.onBlur(e);
        }
    }

    const filterOptions = (opt: DropdownOption) => {
        if (!multiple) return true;
        return !selectedOptions.includes(opt);
    }

    const removeSelectedOptions = (opt: DropdownOption): void => {
        const newOptions = selectedOptions.filter(o => o.value !== opt.value)
        setSelectedOptions(newOptions);
        if (onChange) {
            onChange({
                name,
                value: newOptions.map(o => o.value),
                options: newOptions,
            });
        }
    }

    useEffect(() => {
        if (focus === true && optionsRef.current) {
            setUp(optionsRef.current.getBoundingClientRect().bottom > window.innerHeight + 50);
        } else {
            setUp(false);
        }
    }, [focus, actualOptions]);

    useEffect(() => {
        if (multiple && value) {
            const opts = actualOptions.filter(o => value.includes(o.value));
            setSelectedOptions(opts);
        } else {
            const opt = find(actualOptions, { value });
            setSelectedOption(opt ? opt : defOpt);
        }
        setDefOpt(undefined);
    }, [value, actualOptions, defOpt, multiple]);

    useEffect(() => {
        window.addEventListener('click', () => handleFocus(false));
        window.addEventListener('blur-dropdown', () => handleFocus(false));
        return () => {
            window.removeEventListener('click', () => handleFocus(false));
            window.removeEventListener('blur-dropdown', () => handleFocus(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const renderOptions = (): JSX.Element => {
        const opts = actualOptions.filter(filter);

        return (
            <div
                className={[
                    styles.options,
                    error ? styles.error : '',
                ].join(' ')}
                ref={optionsRef}
            >
                {opts.length > 0 && opts.filter(filterOptions).map((o, index) => {
                    return o.type === 'group' ? (
                        <div
                            className={[
                                styles.option,
                                styles.optionGroup,
                            ].join(' ')}
                            key={`option-${index}`}
                        >
                            {o.text}
                        </div>
                    ) : (
                        <div
                            className={[
                                styles.option,
                                o.disabled ? styles.disabled : '',
                            ].join(' ')}
                            key={`option-${index}`}
                            onClick={() => !o.disabled && handleClick(o)}
                        >
                            {o.text}
                        </div>
                    );
                })}
                {opts.length <= 0 && (
                    <div className={styles.option}>
                        <em style={{ opacity: .5 }}>{autoComplete && query === '' ? 'Zoeken...' : 'Geen resultaten'}</em>
                    </div>
                )}
            </div>
        )
    }

    const renderSelectedOptions = (): JSX.Element => {
        return (
            <div className={styles.selectedOptions}>
                {selectedOptions.map((opt, index) => (
                    <div key={`multi-opt-${index}`}>
                        <span>{opt.text}</span>
                        {extendSelected && <div className={styles.selectedOptionExtension}>
                            {extendSelected(opt)}
                        </div>}
                        <Button
                            color="transparent"
                            icon={faTrashAlt}
                            onClick={() => removeSelectedOptions(opt)}
                        />
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div
            className={c([
                styles.base,
                focus ? styles.focus : '',
                up ? styles.up : '',
                className,
            ])}
            onClick={(e) => { e.stopPropagation(); }}
        >
            <div
                className={[
                    styles.input,
                    focus ? styles.focus : '',
                    error ? styles.error : '',
                ].join(' ')}
                onClick={(e) => { e.stopPropagation(); }}
            >
                <input
                    tabIndex={props.tabIndex}
                    onFocus={() => handleFocus(true)}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder={focus ? (selectedOption?.text || '') : (placeholder || 'Selecteren')}
                    value={focus && query === '' ? '' : (query === '' ? (selectedOption?.text || (defaultOption || '')) : query)}
                />
                {clearable && selectedOption ? (
                    <Button
                        color="transparent"
                        icon={faTimes}
                        onClick={(e) => handleClick()}
                    />
                ) : (
                    <FontAwesomeIcon icon={focus ? faCaretUp : faCaretDown} style={{ pointerEvents: 'none' }} />
                )}
            </div>
            {focus && renderOptions()}
            {multiple && renderSelectedOptions()}
        </div>
    );
}

export default Dropdown;
