import { AxiosPromise } from 'axios';
import qs from 'qs';
import { ApiPaginatedResponse, request } from '../../api';

export interface ApiUser {
    id: string,
    firstName: string,
    affix: string,
    lastName: string,
    fullName: string,
    emailAddress: string,
    telephoneNumber: string,
    isActive: boolean,
    has2fa: boolean,
    roles: number[],
    customers: any[],
    dashboards: any[],
    scopes?: string[],
    rlsKey?: string,
    resetLink?: string,
}

const rest = {
    delete: (userId: string): AxiosPromise => {
        return request.delete(`users/${userId}`);
    },
    get: (userId: string, qWith?: string): AxiosPromise<ApiUser> => {
        return request.get(`users/${userId}${qWith ? `?with=${qWith}` : ''}`);
    },
    list: (
        query?: { [key: string]: any },
        qWith?: string,
    ): AxiosPromise<ApiPaginatedResponse<ApiUser[]>> => {
        return request.get(`users?${qs.stringify({
            ...query,
            with: qWith,
        })}`);
    },
    put: (user: Partial<ApiUser>): AxiosPromise<ApiUser> => {
        return request.post(`users${user.id ? `/${user.id}` : ''}`, user);
    },
    resetPassword: (userId: string): AxiosPromise => {
        return request.post(`users${userId ? `/${userId}` : ''}/reset-password`);
    },
}

export default rest;
