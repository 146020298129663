import React, { FC } from 'react';
import { c } from '../../../lib/util';
import Dropdown, { DropdownProps } from '../../components/Dropdown';
import styles from './styles.module.scss';

export interface FormDropdownProps extends DropdownProps {
    inline?: boolean,
    info?: string,
    label?: string,
    required?: boolean,
}

const FormInput: FC<FormDropdownProps> = ({ inline, info, label, required, ...props }): JSX.Element => {
    return (
        <div
            className={c([
                styles.group,
                inline && styles.inline,
                props.error && styles.hasError,
            ])}
        >
            {label && <label className={styles.label}>
                {label}
                {required && <span> *</span>}
            </label>}
            <div>
                <Dropdown {...props} />
                {(typeof props.error === 'string' || info) && (
                    <p className={styles.info}>
                        {props.error || info}
                    </p>
                )}
            </div>
        </div>
    )
}

export default FormInput;
