import { faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm, Segment, Menu, Table, toast, Button } from '../../kit';
import { ApiCustomer, CustomerApi } from '../../models/Customer';
import { ApiMeta } from '../../api';

interface CustomerListViewProps {
    dashboardId?: string,
}

const CustomerListView: FC<CustomerListViewProps> = ({ dashboardId }): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ customers, setCustomers ] = useState<ApiCustomer[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);
        const limit = dashboardId ? 5 : 20;

        CustomerApi.list({ query, page, dashboardId, limit }).then(({ data }) => {
            setMeta(data.meta);
            setCustomers(data.data);
            setIsLoading(false);
        });
    }

    const deleteCustomer = (customerId: string): void => {
        if (window.confirm('Weet je zeker dat je deze klant wilt verwijderen?')) {
            CustomerApi.delete(customerId).then(() => {
                fetch();
                toast('Klant succesvol verwijderd');
            });
        }
    }

    return (<>
        {!dashboardId && (
            <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Klanten
                <Button
                    color="blue"
                    href="create"
                    icon={faPlus}
                    label="Nieuwe klant"
                />
            </h1>
        )}
        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Naam
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {customers.length > 0 ? customers.map((customer) => (
                        <Table.Row key={`row-${customer.id}`}>
                            <Table.Cell>
                                <Link to={`/admin/customers/${customer.id}/edit`}>
                                    {customer.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`/admin/customers/${customer.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Menu.Item
                                        icon={faTrashAlt}
                                        content="Verwijderen"
                                        onClick={() => deleteCustomer(customer.id)}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default CustomerListView;
