import React, { FC, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../../api';
import { ApiProfile } from '../../../api/auth';
import { Button, Form, toast } from '../../../kit';
import { setProfile } from '../../../reducers/auth';
import store from '../../../store';
import styles from './styles.module.scss';

const LoginView: FC = (): JSX.Element => {
    const [ emailAddress, setEmailAddress ] = useState<string>('');
    const [ password, setPassword ] = useState<string>('');
    const [ secret, setSecret ] = useState<string>('');
    const [ needSecret, setNeedSecret ] = useState<boolean>(false);
    const [ resetPassword, setResetPassword ] = useState<boolean>(false);
    const navigate = useNavigate();
    
    const login = (e: FormEvent): void => {
        e.preventDefault();
        
        api.login(emailAddress, password, secret).then((profile) => {
            if ((profile as unknown as boolean) === true) {
                setNeedSecret(true);
            } else {
                store.dispatch(setProfile(profile as unknown as ApiProfile));
                navigate('/');
            }
        }).catch(() => {
            toast('Verkeerde gegevens', 'error');
        });
    }

    const sendNewPassword = (e: FormEvent): void => {
        e.preventDefault();

        if (emailAddress === '') {
            toast('Vul je e-mailadres in', 'error');
        } else {
            api.requestReset(emailAddress).then(() => {
                toast('Er is een nieuw wachtwoord verzonden', 'success');
                setResetPassword(false);
            }).catch(() => {});
        }
    }

    return (
        <div className={styles.container}>
            <h1>{resetPassword ? 'Wachtwoord aanvragen' : 'Inloggen'}</h1>
            <Form onSubmit={(e: FormEvent) => resetPassword ? sendNewPassword(e) : login(e)}>
                {needSecret ? (
                    <Form.Input
                        autoFocus
                        label="Voer de 6-cijferige code in vanuit je Authenticator-app"
                        name="code"
                        onChange={({ value }: any) => setSecret(value)}
                        placeholder="Vul de geheime code uit de app in"
                        required
                        type="text"
                        value={secret}
                    />
                ) : (<>
                    <Form.Input
                        autoFocus
                        label="E-mailadres"
                        onChange={({ value }: any) => setEmailAddress(value)}
                        required
                        type="email"
                        value={emailAddress}
                    />
                    {!resetPassword && (
                        <Form.Input
                            label="Wachtwoord"
                            onChange={({ value }: any) => setPassword(value)}
                            required
                            type="password"
                            value={password}
                        />
                    )}
                </>)}
                <Button
                    color="blue"
                    type="submit"
                >
                    {resetPassword ? 'Nieuw wachtwoord verzenden' : 'Inloggen'}
                </Button>
                {resetPassword ? (
                    <Button
                        color="transparent"
                        onClick={() => setResetPassword(false)}
                    >
                        Annuleren
                    </Button>
                ) : (needSecret ? null : (
                    <Button
                        color="transparent"
                        onClick={() => setResetPassword(true)}
                    >
                        Ik ben mijn wachtwoord vergeten
                    </Button>
                ))}
            </Form>
        </div>
    );
}

export default LoginView;
