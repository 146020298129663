import { configureStore } from '@reduxjs/toolkit';
import auth, { AuthState } from './reducers/auth';

export interface AppState {
    auth: AuthState,
}

export default configureStore({
    reducer: {
        auth,
    },
})
