import React, { FC, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { request } from '../../../api';
import { Button, Form, toast } from '../../../kit';
import { ApiUser } from '../../../models/User';
import styles from './styles.module.scss';

const ResetPasswordView: FC = (): JSX.Element => {
    const [ errors, setErrors ] = useState<any>({});
    const [ password, setPassword ] = useState<string>('');
    const [ passwordConfirm, setPasswordConfirm ] = useState<string>('');
    const [ twoData, setTwoData ] = useState<any>();
    const navigate = useNavigate();
    
    const send = (e: FormEvent): void => {
        e.preventDefault();
        setErrors({});
        
        if (password !== passwordConfirm) {
            setErrors({ confirm: 'Wachtwoorden komen niet overeen' });
        } else {
            request.post(`auth/reset-password?${window.location.search.substring(1)}`, {
                password,
            }).then(({ data }) => {
                toast('Je wachtwoord is gewijzigd');
                if (data.qr) {
                    setTwoData(data.qr);
                } else {
                    navigate('/');
                }
            }).catch((err) => {
                setErrors(err.response.data.errors);
            });
        }
    }

    return (
        <div className={styles.container}>
            <h1>Nieuw wachtwoord aanmaken</h1>
            {twoData ? (
                <div className={styles.twoStepContainer}>
                    <p>Scan onderstaande QR code met je authenticator app.<br />Heb je deze niet? Download de app voor <a href="https://apps.apple.com/nl/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">iOS</a> of <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=US" target="_blank" rel="noreferrer" >Android</a>.</p>
                    <img src={twoData.qr} alt="QR" />
                    <p>
                        Dit is jouw geheime sleutel, bewaar deze goed op een veilige plek:<br />
                        <b>{twoData.secret}</b>
                    </p>
                    <Button
                        color="blue"
                        href="/login"
                        style={{ marginTop: 32 }}
                    >
                        Doorgaan
                    </Button>
                </div>
            ) : (
                <Form onSubmit={(e: FormEvent) => send(e)}>
                    <Form.Input
                        error={errors.password}
                        label="Nieuw wachtwoord"
                        onChange={({ value }: any) => setPassword(value)}
                        required
                        type="password"
                        value={password}
                    />
                    <Form.Input
                        error={errors.confirm}
                        label="Herhaal wachtwoord"
                        onChange={({ value }: any) => setPasswordConfirm(value)}
                        required
                        type="password"
                        value={passwordConfirm}
                    />
                    <Button
                        color="blue"
                        type="submit"
                    >
                        Opslaan
                    </Button>
                    <Button
                        color="transparent"
                        href="/"
                    >
                        Annuleren
                    </Button>
                </Form>
            )}
        </div>
    );
}

export default ResetPasswordView;
