import React, { FC, HTMLAttributes, isValidElement } from 'react';
import styles from './styles.module.scss';
import { useKeyUp } from '../../../lib/hooks';
import { c } from '../../../lib/util';
import Button from '../../components/Button';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

type ModalSize = 'large' | 'medium' | 'small';

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    fitOnScreen?: boolean,
    footer?: string | JSX.Element,
    header?: string | JSX.Element,
    onClose?: () => void,
    open: boolean,
    size?: ModalSize,
}

const Modal: FC<ModalProps> = ({
    children,
    className,
    fitOnScreen,
    footer,
    header,
    onClose,
    open,
    size,
    ...props
}): JSX.Element => {
    useKeyUp(() => {
        if (open && onClose) {
            onClose();
        }
    }, ['Escape'], true);
    
    return (
        <div
            className={c([
                styles.overlay,
                open && styles.showModal,
            ])}
            onClick={onClose}
        >
            <div
                className={c([
                    styles.modal,
                    fitOnScreen && styles.fitOnScreen,
                    size && styles[size],
                    className,
                ])}
                onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
                {...props}
            >
                {header && (
                    <div className={styles.header}>
                        <div>
                            {header && isValidElement(header)
                                ? header
                                : <h4 className={styles.title}>{header}</h4>
                            }
                        </div>
                        <Button
                            color="border"
                            icon={faTimes}
                            onClick={onClose}
                        />
                    </div>
                )}
                <div className={styles.body}>
                    {children}
                </div>
                {footer && <div className={styles.footer}>
                    {footer}
                </div>}
            </div>
        </div>
    );
}

export default Modal;
