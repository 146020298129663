import React, { FC, useState } from 'react';
import api from '../../api';
import { Button, Checkbox, Segment, toast, Confirm, Modal } from '../../kit';
import { ApiUser } from '../../models/User';
import styles from './styles.module.scss';

type TwoModal = '' | 'cancel' | 'set';

interface TwoStepProps {
    onUpdate: (state: boolean) => void,
    user: Partial<ApiUser>,
}

const TwoStep: FC<TwoStepProps> = ({ onUpdate, user }): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ twoModal, setTwoModal ] = useState<TwoModal>('');
    const [ twoModalData, setTwoModalData ] = useState<any>({});
    
    const handleTwoStep = ({ value }: any): void => {
        if (value) {
            saveTwoStep(true);
        } else {
            setTwoModal('cancel');
        }
    }

    const saveTwoStep = (state: boolean): void => {
        setIsLoading(true);
        api.saveTwoStep(state).then(({ data }) => {
            onUpdate(state);
            setIsLoading(false);

            if (state) {
                setTwoModal('set');
                setTwoModalData(data);
            } else {
                toast('Twee-staps authenticatie uitgeschakeld', 'success');
            }
        });
    }

    return (
        <Segment header="Beveiliging" isLoading={isLoading}>
            <Checkbox
                checked={user.has2fa}
                label="Twee-staps authenticatie inschakelen"
                onChange={handleTwoStep}
                toggle
            />
            {twoModal === 'cancel' && (
                <Confirm
                    content="Weet je zeker dat je twee-staps authenticatie wilt uitschakelen? Dit maakt je account minder veilig."
                    manual={true}
                    onCancel={() => setTwoModal('')}
                    onConfirm={() => saveTwoStep(false)}
                />
            )}
            {twoModal === 'set' && (
                <Modal
                    footer={(
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                color="blue"
                                label="Sluiten"
                                onClick={() => { setTwoModalData({}); setTwoModal(''); }}
                            />
                        </div>
                    )}
                    header="Twee-staps authenticatie"
                    onClose={() => { setTwoModalData({}); setTwoModal(''); }}
                    open={true}
                    size="medium"
                >
                    <div className={styles.twoStepContainer}>
                        <p>Scan onderstaande QR code met je authenticator app.<br />Heb je deze niet? Download de app voor <a href="https://apps.apple.com/nl/app/google-authenticator/id388497605" target="_blank" rel="noreferrer">iOS</a> of <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=nl&gl=US" target="_blank" rel="noreferrer" >Android</a>.</p>
                        <img src={twoModalData.qr} alt="QR" />
                        <p>
                            Dit is jouw geheime sleutel, bewaar deze goed op een veilige plek:<br />
                            <b>{twoModalData.secret}</b>
                        </p>
                    </div>
                </Modal>
            )}
        </Segment>
    );
}

export default TwoStep;
