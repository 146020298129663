import { faFileExcel, faPlus, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Confirm, Segment, Menu, Table, toast, Button } from '../../kit';
import { ApiDashboard, DashboardApi } from '../../models/Dashboard';
import { ApiMeta } from '../../api';
import { apiUrl } from '../../lib/auth';

const DashboardListView: FC = (): JSX.Element => {
    const [ isLoading, setIsLoading ] = useState(true);
    const [ meta, setMeta ] = useState<ApiMeta>();
    const [ dashboards, setDashboards ] = useState<ApiDashboard[]>([]);

    const fetch = (query?: string, page: number = 1): void => {
        setIsLoading(true);

        DashboardApi.list({ query, page, }).then(({ data }) => {
            setMeta(data.meta);
            setDashboards(data.data);
            setIsLoading(false);
        });
    }

    const deleteDashboard = (dashboardId: string): void => {
        if (window.confirm('Weet je zeker dat je dit dashboard wilt verwijderen?')) {
            DashboardApi.delete(dashboardId).then(() => {
                fetch();
                toast('Dashboard succesvol verwijderd');
            });
        }
    }

    return (<>
        <h1 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Dashboards
            <div>
                <Button
                    color="border"
                    href={apiUrl('dashboards/export')}
                    icon={faFileExcel}
                    label="Exporteren"
                    target="_blank"
                />
                <Button
                    color="blue"
                    href="create"
                    icon={faPlus}
                    label="Nieuw dashboard"
                />
            </div>
        </h1>
        <Segment isLoading={isLoading}>
            <Table.Actions
                autoLoad
                onSearch={fetch}
                remember
            />
            <Table fluid>
                <thead>
                    <Table.Row>
                        <Table.HeaderCell>
                            Naam
                        </Table.HeaderCell>
                        <Table.HeaderCell collapsing />
                    </Table.Row>
                </thead>
                <tbody>
                    {dashboards.length > 0 ? dashboards.map((dashboard) => (
                        <Table.Row key={`row-${dashboard.id}`}>
                            <Table.Cell>
                                <Link to={`${dashboard.id}/edit`}>
                                    {dashboard.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell collapsing>
                                <Menu dropdown>
                                    <Menu.Item
                                        icon={faPencil}
                                        content="Wijzigen"
                                        href={`${dashboard.id}/edit`}
                                    />
                                    <Menu.Divider />
                                    <Menu.Item
                                        icon={faTrashAlt}
                                        content="Verwijderen"
                                        onClick={() => deleteDashboard(dashboard.id)}
                                    />
                                </Menu>
                            </Table.Cell>
                        </Table.Row>
                    )) : (
                        <Table.Row noResults />
                    )}
                </tbody>
            </Table>
        </Segment>
        {meta && <Table.Pagination
            meta={meta}
            onChange={(page: number) => fetch('', page)}
        />}
    </>);
}

export default DashboardListView;
