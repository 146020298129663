import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Banner from '../../components/Banner';
import { useProfile } from '../../lib/auth';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

const HomepageView: FC = (): JSX.Element => {
    const profile = useProfile();

    if (!profile) {
        return <Loader />;
    }

    return (<>
        <Banner />
        <div className={styles.container}>
            <div className="row" style={{ paddingTop: 32 }}>
                {profile.dashboards.map((dashboard) => (
                    <div className="col-sm-6 col-md-3 col-lg-4" key={dashboard.id}>
                        <Link
                            className={styles.tile}
                            to={`/dashboards/${dashboard.id}`}
                        >
                            {dashboard.imagePath && (
                                <img src={dashboard.imagePath} alt={dashboard.name} />
                            )}
                            <span>{dashboard.displayName || dashboard.name}</span>
                            <FontAwesomeIcon icon={faChevronRight} />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    </>);
}

export default HomepageView;
