import React, { FC, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import api from '../../api';
import { hasRole, useProfile } from '../../lib/auth';
import { c } from '../../lib/util';
import logo from './logo.png';
import styles from './styles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUser } from '@fortawesome/pro-solid-svg-icons';

const TopBar: FC = (): JSX.Element => {
    const [ height, setHeight ] = useState<number>(0);
    const [ sticky, setSticky ] = useState<boolean>(false);
    const [ showProfile, setShowProfile ] = useState<boolean>(false);
    const [ dontHide, setDontHide ] = useState<boolean>(true);
    const isMoving = useRef<boolean>(false);
    const { pathname } = useLocation();
    const profile = useProfile();
    const isDashboard = /^\/dashboards/.test(pathname);

    useEffect(() => {
        setShowProfile(false);
    }, [pathname]);

    useEffect(() => {
        let id: any = null;

        if (dontHide && !isMoving.current) {
            id = setTimeout(() => setDontHide(false), 2500);
        }

        return () => {
            clearTimeout(id);
        }
    }, [dontHide]);

    useEffect(() => {
        const handleResize =(): void => {
            const topBar = document.getElementById('top-bar');
            if (topBar) setHeight(topBar.clientHeight);
        }
        const handleScroll = (): void => {
            setSticky(window.scrollY > 0);
            setTimeout(() => handleResize(), 250);
        }
        handleScroll();
        window.addEventListener('resize', handleResize, true);
        window.addEventListener('scroll', handleScroll, true);
        return () => {
            window.removeEventListener('resize', handleScroll, true);
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    return (<>
        <div
            onMouseEnter={() => setDontHide(true)}
            onMouseMove={() => isMoving.current = true}
            onMouseLeave={() => setDontHide(false)}
        >
            <div
                id="top-bar"
                className={c([
                    styles.base,
                    sticky && !isDashboard && styles.isSticky,
                    isDashboard && !dontHide && styles.autoHide,
                ])}
            >
                <div className={styles.navigation}>
                    <div>
                        <Link
                            className={c([
                                styles.navItem,
                                pathname === `/` && styles.isActive
                            ])}
                            to="/"
                        >
                            <FontAwesomeIcon icon={faHome} />
                        </Link>
                        {profile && profile.dashboards.map((dashboard) => (
                            <Link
                                key={`nav-${dashboard.id}`}
                                className={c([
                                    styles.navItem,
                                    pathname === `/dashboards/${dashboard.id}` && styles.isActive
                                ])}
                                to={`/dashboards/${dashboard.id}`}
                            >
                                {dashboard.displayName}
                            </Link>
                        ))}
                    </div>
                    <div>
                        {profile && (
                            <div className={styles.profile}>
                                <div onClick={() => setShowProfile(!showProfile)}>
                                    <FontAwesomeIcon icon={faUser} />
                                </div>
                                <div className={`${styles.dropdown} ${showProfile && styles.on}`}>
                                    {hasRole(profile, [1]) && (<>
                                        <Link
                                            className={c([
                                                styles.navItem,
                                                pathname.includes('/admin/customers') && styles.isActive
                                            ])}
                                            to="/admin/customers"
                                        >
                                            Klanten
                                        </Link>
                                        <Link
                                            className={c([
                                                styles.navItem,
                                                pathname.includes('/admin/users') && styles.isActive
                                            ])}
                                            to="/admin/users"
                                        >
                                            Gebruikers
                                        </Link>
                                        <Link
                                            className={c([
                                                styles.navItem,
                                                pathname.includes('/admin/dashboards') && styles.isActive
                                            ])}
                                            to="/admin/dashboards"
                                        >
                                            Dashboards
                                        </Link>
                                    </>)}
                                    {hasRole(profile, [2]) && !hasRole(profile, [1]) && (
                                        <Link
                                            className={c([
                                                styles.navItem,
                                                pathname.includes('/admin/users') && styles.isActive
                                            ])}
                                            to="/admin/users"
                                        >
                                            Gebruikers
                                        </Link>
                                    )}
                                    <Link
                                        className={c([
                                            styles.navItem,
                                            pathname.includes('/account') && styles.isActive
                                        ])}
                                        to="/account"
                                    >
                                        Mijn account
                                    </Link>
                                    <span
                                        className={styles.navItem}
                                        onClick={() => api.logout()}
                                    >
                                        Uitloggen
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {!isDashboard && <div style={{ height }} />}
            {isDashboard && <div className={styles.trigger} />}
        </div>
    </>);
}

export default TopBar;
